<template>
  <div class="available-date-form">
    <div class="available-date-form__content-wrapper">
      <hs-heading class="available-date-form__heading">
        {{ title }}
        <span class="available-date-form__heading-descr" slot="description">
          Dates that are unavailable are marked in red below.
        </span>
      </hs-heading>
      <section class="available-date-form__elements-wrapper">
        <h2 class="available-date-form__elements-title">
          Selected drop off day: {{ formattedDate }}
        </h2>
        <div
          class="available-date-form__elements-side available-date-form__elements-side--with-picker"
        >
          <hs-month-calendar
            :active-date="chosenDate"
            :available-dates="availableDates"
            class="available-date-form__picker"
            @changeday="(val) => $emit('changeday', val)"
          />
        </div>
        <!-- todo: Consider using CarryInRepairOperationgHours -->
        <div class="available-date-form__elements-side">
          <div class="available-date-form__schedule-wrapper">
            <p class="available-date-form__schedule-note">
              Please bring your device on the date you’ve selected. You may drop off the device at
              anytime during the {{ locationName }} hours listed below:
            </p>
            <p class="available-date-form__schedule-text">{{ timeString }}</p>
          </div>
        </div>
      </section>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['availableDates', 'chosenDate', 'locationName', 'startTime', 'endTime'],
  computed: {
    timeString() {
      if (this.startTime && this.endTime) {
        const startArr = this.startTime.split(':');
        const endArr = this.endTime.split(':');
        if (+startArr[0] > 11) {
          startArr[0] = +startArr[0] - 12;
          startArr.push('pm');
        } else {
          startArr[0] = +startArr[0];
          startArr.push('am');
        }
        if (+endArr[0] > 11) {
          endArr[0] = +endArr[0] - 12;
          endArr.push('pm');
        } else {
          endArr[0] = +endArr[0];
          endArr.push('am');
        }
        return `${startArr[0]}:${startArr[1]} ${startArr[3]} - ${endArr[0]}:${endArr[1]} ${endArr[3]}`;
      }
      return '';
    },
    title() {
      return `Please select a date to drop off your device at: ${this.locationName}`;
    },
    formattedDate() {
      if (this.chosenDate) {
        const dateArr = this.chosenDate.split('-');
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
      }
      return 'not selected';
    },
  },
};
</script>

<style scoped lang="scss">
.available-date-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  &__content-wrapper {
    flex-grow: 1;
    padding-bottom: 25px;
  }
  &__sticky-cont {
    flex-grow: 0;
    flex-wrap: wrap;
  }
  &__elements-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 27px;
  }
  &__elements-title {
    width: 100%;
    margin-bottom: 13px;
    font-weight: 700;
    font-size: 20px;
  }
  &__elements-side {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 20px;

    @include mf($gridMDBreakpoint) {
      width: calc(50% - 20px);
      margin-bottom: 0;
    }
    &--with-picker {
      @include mf($gridMDBreakpoint) {
        max-width: 450px;
        margin-right: 40px;
      }
    }
  }
  &__picker {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border: 1px solid #dfe4ef;
    border-radius: 4px;
  }
  &__schedule-wrapper {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px;
    color: $darkGray;
    text-align: center;
    background-color: #fcfcfc;
    border: 1px dashed $lightGray;
    border-radius: 4px;

    @include mf($gridMDBreakpoint) {
      margin: 0;
    }
  }
  &__schedule-note {
    margin-bottom: 15px;
  }
  &__schedule-text {
    font-weight: 700;
  }
}
</style>
<style lang="scss">
.available-date-form .hs-heading__description {
  max-width: initial;
  margin-top: 10px;
  color: $darkGray;
  font-weight: 500;
  font-size: 16px;
}
</style>
