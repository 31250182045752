<template>
  <div class="find-location">
    <claim-location-form
      :radios="radios"
      :chosen-location="chosenLocation"
      :zip="zipcode"
      title="Please select a service center location:"
      @change="SET_CHOSEN_LOCATION"
    >
      <StepNavigationButtons
        @back="goBack"
        @confirm="goToDateStep"
        :disabled="!chosenLocation"
        confirm-button-text="Schedule Drop off"
      />
    </claim-location-form>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';
import {getRequestBody} from '@/utils/request';
import ClaimLocationForm from '@/components/shared/ClaimLocationForm';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';

export default {
  components: {
    ClaimLocationForm,
    StepNavigationButtons,
  },
  computed: {
    ...mapState('requestService', [
      'productCode',
      'brandCode',
      'productLine',
      'triageReferenceId',
      'serialNumber',
      'issue',
      'issues',
      'address',
    ]),
    ...mapState('requestService', ['availabilityResponses', 'address']),
    ...mapState('requestService/carryInRepair', ['serviceTypeCode', 'locations', 'chosenLocation']),
    ...mapGetters('requestService/carryInRepair', ['locations']),
    ...mapState('user', ['customerDetails']),
    ...mapGetters('requestService', ['zipcode']),
    radios() {
      return {
        name: 'carry-in-location',
        list: this.locations,
      };
    },
  },
  methods: {
    ...mapActions('requestService/carryInRepair', ['GET_ZIP', 'GET_LOCATIONS', 'GET_SLOTS']),
    ...mapMutations('requestService/carryInRepair', ['SET_CHOSEN_LOCATION']),
    async goToDateStep() {
      await this.GET_SLOTS({zipCode: this.zipcode});
      const {params, query} = this.$route;
      this.$router.push({
        name: this.$route.name,
        params,
        query: {...query, step: 'date'},
      });
    },
    goBack() {
      const {params, query} = this.$route;
      this.SET_CHOSEN_LOCATION('');
      this.$router.push({
        name: this.$route.name,
        params,
        query: {...query, step: 'explanation'},
      });
    },
  },
  created() {
    this.SET_CHOSEN_LOCATION('');
  },
};
</script>

<style scoped lang="scss">
.find-location {
  padding-bottom: 200px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }
}
</style>
