<template>
  <div class="claim-location-form">
    <div class="claim-location-form__content-wrapper">
      <hs-heading class="claim-location-form__heading">{{ title }}</hs-heading>
      <p class="claim-location-form__zip-text">Showing locations near to zip code: {{ zip }}</p>
      <hs-radio
        v-for="radio in radios.list"
        :key="radio.id"
        :name="radios.name"
        :value="radio.id"
        :checked="radio.id === chosenLocation"
        class="claim-location-form__radio"
        @change="(payload) => $emit('change', payload.value)"
      >
        <section class="claim-location-form__radio-content">
          <p class="claim-location-form__location-name">
            {{ radio.text }}
          </p>
          <!-- <p class="claim-location-form__location-distance">
            {{ radio.distance }} miles
          </p> -->
          <GetDirections
            :from="radio.from"
            :to="radio.to"
            class="claim-location-form__direction-btn"
          />
        </section>
      </hs-radio>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import GetDirections from '@/components/shared/GetDirections';

export default {
  components: {
    GetDirections,
  },
  props: ['title', 'zip', 'radios', 'chosenLocation'],
};
</script>

<style scoped lang="scss">
.claim-location-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  &__content-wrapper {
    flex-grow: 1;
  }
  &__sticky-cont {
    flex-grow: 0;
  }
  &__zip-text {
    margin-bottom: 22px;
    font-weight: 700;
    font-size: 20px;

    @include mf($gridMDBreakpoint) {
      margin-bottom: 40px;
    }
  }
  &__radio {
    margin-bottom: 0;
    padding: 20px 10px;
    border-top: 1px solid $gray;
    &:last-of-type {
      border-bottom: 1px solid $gray;

      @include mf($gridMDBreakpoint) {
        border-bottom: 0;
      }
    }

    @include mf($gridMDBreakpoint) {
      margin-bottom: 34px;
      padding: 0;
      border-top: 0;
    }
  }
  &__radio-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 30px;
    font-size: 18px;

    @include mf($gridMDBreakpoint) {
      font-size: 16px;
    }
  }
  &__location-name {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
  }
  &__location-distance {
    display: flex;
    align-items: center;
    margin-right: 14px;
    color: $mediumGray;
    &::before {
      display: block;
      width: 11px;
      height: 16px;
      margin-right: 7px;
      background-image: url('~@/assets/images/claim/distance-location.svg');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      content: '';
    }
  }
  &__direction-btn {
    margin-left: auto;

    @include mf($gridMDBreakpoint) {
      margin-left: 0;
    }
  }
}
</style>
<style>
.claim-location-form__radio .get-direction {
  position: relative;
}
.claim-location-form__radio .pretty input {
  z-index: auto;
}
.claim-location-form__radio .pretty .state label {
  text-indent: initial;
}
.claim-location-form__radio .hs-radio.pretty .state label::before,
.claim-location-form__radio .hs-radio.pretty .state label::after {
  top: 7px;
  pointer-events: none;
}
</style>
