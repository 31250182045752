import {mapActions, mapState} from 'vuex';

export default {
  methods: {
    ...mapActions('requestService', ['INIT_MASTER_CLAIM']),
    /*
    {
      serviceTypeCode,
      successRouteName,
      disableServify = false,
      requestParams = {},
      consumerServiceRequestParams = {},

      subStatusCode, initial substatus code (serviceJobSubStatus)
      subStatus, initial substatus text (serviceJobSubStatusName, currently doesn't work)
    }
    */
    async createJob(payload) {
      const {successRouteName} = payload;
      delete payload.successRouteName;

      const {customerRef, contractRef} = this.$route.query;
      const inventoryEquipmentId = this.$route.params.id;
      const contract = this.contractDetails || {};
      const PartnerCode = contract?.partnerCode;

      const initClaimPayload = {
        inventoryEquipmentId,
        encryptedRequestParams: {
          customerRef,
          contractRef,
        },

        PartnerCode,
        ...payload,
      };

      if (!initClaimPayload.disableServify) {
        initClaimPayload.getRedirectUrl = (id) => {
          return this.getRedirectFromPaymentUrl(id, successRouteName);
        };
      }

      return this.INIT_MASTER_CLAIM(initClaimPayload);
    },

    getRedirectFromPaymentUrl(id, successRouteName) {
      const {query} = this.$route;
      delete query.step;

      const {href} = this.$router.resolve({
        name: successRouteName,
        params: {
          id,
        },
        query: {
          ...query,
          case: 'create',
        },
      });

      return `${window.location.origin}${href}`;
    },
  },
  computed: {
    ...mapState('user', ['contractDetails']),
  },
};
