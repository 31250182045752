<template>
  <div class="choose-date">
    <date-form
      :available-dates="availableDates"
      :chosen-date="chosenDate"
      :start-time="scheduledFromTime"
      :end-time="scheduledToTime"
      :location-name="locationName"
      @changeday="SET_CHOSEN_DATE"
    >
      <StepNavigationButtons
        @back="goBack"
        @confirm="createClaim"
        :disabled="!chosenDate || paymentLoader"
        one-more-step
        confirm-button-text="Proceed to Payment"
      />
    </date-form>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';
import DateForm from '@/components/claim/components/carry-in-repair/CarryInAvailableDateForm';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import createJobMixin from '@/mixins/createJobMixin';

export default {
  components: {
    DateForm,
    StepNavigationButtons,
  },
  mixins: [createJobMixin],
  computed: {
    ...mapState('requestService/carryInRepair', ['chosenLocation', 'chosenDate', 'slots']),
    ...mapGetters('requestService/carryInRepair', ['locationInfo']),
    ...mapState('requestService', ['paymentLoader']),
    availableDates() {
      let res = [];
      if (this.slots && this.slots.length) {
        res = this.slots
          ?.filter((s) => s.slots.some((e) => e.isActive === 'true'))
          .map((i) => i.date);
      }
      return res;
    },
    locationName() {
      return this.locationInfo?.text;
    },
    serviceProviderAddress() {
      return this.locationInfo?.to;
    },
    scheduledFromTime() {
      if (this.chosenDate) {
        const chosenDateSlotObj = this.slots.find((i) => i.date === this.chosenDate);
        return chosenDateSlotObj?.slots && chosenDateSlotObj?.slots.length
          ? chosenDateSlotObj?.slots[0]?.startTimeval
          : '08:00:00';
      }
      return '';
    },
    scheduledToTime() {
      if (this.chosenDate) {
        const chosenDateSlotObj = this.slots.find((i) => i.date === this.chosenDate);
        return chosenDateSlotObj?.slots && chosenDateSlotObj?.slots.length
          ? chosenDateSlotObj?.slots[0]?.endTimeval
          : '08:00:00';
      }
      return '';
    },
  },
  methods: {
    ...mapMutations('requestService', ['SET_PAYMENT_PROGRESS']),
    ...mapActions('requestService/carryInRepair', ['GET_AVAILABLE_DATES']),
    ...mapMutations('requestService/carryInRepair', ['SET_CHOSEN_DATE', 'CLEAR_STORE']),

    async createClaim() {
      this.SET_PAYMENT_PROGRESS(true);

      const {scheduledFromTime, scheduledToTime} = this;

      const {paymentUrl} = await this.createJob({
        serviceTypeCode: 'CLAIM_CARRYIN',
        successRouteName: 'CarryInRepairSuccess',

        consumerServiceRequestParams: {
          scheduledDateTime: this.chosenDate,
          scheduledFromTime,
          scheduledToTime,
          serviceLocationCode: this.locationInfo?.id || this.chosenLocation,
          serviceLocationName: this.locationName,
          serviceProviderAddress: this.serviceProviderAddress,
        },
      });

      window.location = paymentUrl;
    },
    goBack() {
      const {params, query} = this.$route;
      this.SET_CHOSEN_DATE('');
      this.$router.push({
        name: 'StartSpecificClaim',
        params,
        query: {...query, step: 'location'},
      });
    },
    replaceRouteOnCreated() {
      const {params, query} = this.$route;
      this.$router.replace({
        name: 'StartSpecificClaim',
        params,
        query: {...query, step: 'explanation'},
      });
    },
  },
  created() {
    this.SET_CHOSEN_DATE('');
    if (!this.locationInfo?.id && !this.chosenLocation) {
      this.replaceRouteOnCreated();
    } else {
      this.GET_AVAILABLE_DATES(this.locationInfo?.id || this.chosenLocation);
    }
  },
};
</script>

<style scoped lang="scss">
.choose-date {
  padding-bottom: 200px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }
}
</style>
