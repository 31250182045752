<template>
  <div class="get-direction" @keydown.esc="hide" @click="handleClose">
    <a
      class="hs-link"
      :href="`http://maps.apple.com/?saddr=${from}&daddr=${to}`"
      target="_blank"
      v-if="device === 'ios'"
    >
      {{ getDirectionsText }}
    </a>
    <a
      class="hs-link"
      :href="`https://www.google.com/maps?saddr=${from}&daddr=${to}`"
      target="_blank"
      v-else-if="device === 'android'"
    >
      {{ getDirectionsText }}
    </a>
    <button v-else class="hs-link" @click="showPopup = true">
      {{ getDirectionsText }}
    </button>
    <div
      class="get-direction__popup"
      v-if="showPopup && device === 'desktop'"
      aria-labelledby="demo-modal-titile"
      aria-describedby="demo-modal-desc"
      @hide="hide"
    >
      <div class="get-direction__content">
        <iframe
          width="100%"
          height="100%"
          title=""
          style="width: 100%; height: 100%; border: 0"
          loading="lazy"
          allowfullscreen
          :src="`https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${from}&destination=${to}&avoid=tolls|highways`"
        >
        </iframe>
        <button @click.prevent="showPopup = false" class="get-direction__close">x</button>
      </div>
    </div>
    <span v-if="showPopup" class="get-direction__overlay"></span>
  </div>
</template>

<script>
import googleAPIKey from '@/constants/google';

const GET_DIRECTIONS_TEXT = 'Get Directions';

export default {
  props: {
    from: String,
    to: String,
  },
  data() {
    return {
      apiKey: googleAPIKey,
      device: 'desktop',
      showPopup: false,
    };
  },
  methods: {
    hide() {
      this.showPopup = false;
    },
    handleClose(e) {
      if (this.showPopup && this.device === 'desktop') {
        const target = e.target;
        if (e.target.className === 'get-direction__popup') {
          this.hide();
        }
      }
    },
    getOS() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // generate a link to Google maps for Windows and Android phones
      if (/windows phone/i.test(userAgent) || /android/i.test(userAgent)) {
        return 'android';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
      }

      return 'desktop';
    },
  },
  computed: {
    getDirectionsText() {
      return GET_DIRECTIONS_TEXT;
    },
  },
  beforeMount() {
    this.getOS();
  },
};
</script>

<style scoped lang="scss">
.get-direction {
  display: inline-block;
  &__popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background: rgba(#3a3a3a, 0.2);
  }
  &__content {
    position: absolute;
    top: 10vh;
    left: 10vw;
    width: 80vw;
    height: 80vh;
    background-color: #ffffff;
  }
  &__close {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    text-indent: -9999px;
    background: #ffffff url(~@/assets/images/get-direction-close.svg) no-repeat 50%/9px 9px;
    border-radius: 16px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  }
}
</style>
